import { mobileScreenWidth } from "./constants";

document.addEventListener("DOMContentLoaded", afterDOMContentLoaded);

let isGalleryTopVisible = false;
let isGalleryBottomVisible = false;
let lastScrollY = 0;
let currentTopGalleryTranslateX = 0;
let currentBottomGalleryTranslateX = 0;
let scrollPixelMultiplier = 0;

function afterDOMContentLoaded() {
  const galleryTopRow = document.getElementById("gallery-top-scrolling-row");
  const galleryBottomRow = document.getElementById(
    "gallery-bottom-scrolling-row"
  );

  setupGalleryTopIntersectionObserver();
  setupGalleryBottomIntersectionObserver();

  window.addEventListener(
    "scroll",
    () => onWindowScroll(galleryTopRow, galleryBottomRow),
    { passive: true }
  );

  window.addEventListener("resize", () => calculateScrollModifier(), {
    passive: true,
  });
}

function setupGalleryTopIntersectionObserver() {
  const observer = new IntersectionObserver((entries) => {
    if (entries.length > 0) {
      isGalleryTopVisible = entries[0].isIntersecting;

      if (isGalleryTopVisible) {
        calculateScrollModifier();
      }
    }
  });
  observer.observe(document.getElementById("gallery-top-scrolling-row"));
}

function setupGalleryBottomIntersectionObserver() {
  const observer = new IntersectionObserver((entries) => {
    if (entries.length > 0) {
      isGalleryBottomVisible = entries[0].isIntersecting;

      if (isGalleryBottomVisible) {
        calculateScrollModifier();
      }
    }
  });
  observer.observe(document.getElementById("gallery-bottom-scrolling-row"));
}

function onWindowScroll(
  galleryTopRow: HTMLElement,
  galleryBottomRow: HTMLElement
) {
  const scrollDiff = lastScrollY ? Math.abs(window.scrollY - lastScrollY) : 0;
  const translateAmount = scrollDiff * scrollPixelMultiplier;

  if (window.scrollY > lastScrollY) {
    // scrolling down

    if (isGalleryTopVisible) {
      currentTopGalleryTranslateX += translateAmount;
      galleryTopRow.style.transform = `translateX(${currentTopGalleryTranslateX}px)`;
    }

    if (isGalleryBottomVisible) {
      currentBottomGalleryTranslateX += translateAmount;
      galleryBottomRow.style.transform = `translateX(-${currentBottomGalleryTranslateX}px)`;
    }
  } else {
    // scrolling up

    if (
      isGalleryTopVisible &&
      currentTopGalleryTranslateX - translateAmount > 0
    ) {
      currentTopGalleryTranslateX -= translateAmount;
      galleryTopRow.style.transform = `translateX(${currentTopGalleryTranslateX}px)`;
    }

    if (
      isGalleryBottomVisible &&
      currentBottomGalleryTranslateX - translateAmount > 0
    ) {
      currentBottomGalleryTranslateX -= translateAmount;
      galleryBottomRow.style.transform = `translateX(-${currentBottomGalleryTranslateX}px)`;
    }
  }

  lastScrollY = window.scrollY;
}

function calculateScrollModifier() {
  if (window.innerWidth > 2500) {
    scrollPixelMultiplier = 0.25;
  } else if (window.innerWidth > 2000) {
    scrollPixelMultiplier = 0.5;
  } else if (window.innerWidth > mobileScreenWidth) {
    scrollPixelMultiplier = 0.75;
  } else {
    scrollPixelMultiplier = 1;
  }
}
